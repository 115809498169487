import SectionComponent from '@components/SectionComponent'
import SeoComponent from '@components/SeoComponent'
import SpacerComponent from '@components/SpacerComponent'
import SquaredLinkComponent from '@components/SquaredLinkComponent'
import { Paragraph, Title1 } from '@components/Typography'
import Grid from '@components/ViewGridComponent'
import { whiteTheme } from '@src/themes'
import { useLottie } from 'lottie-react'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import LemonTravolta from '../animations/LemonTravolta.json'

const Lemon = () => {
  const options = {
    animationData: LemonTravolta,
    loop: false,
    autoplay: true,
  }
  const { View } = useLottie(options)

  return View
}

const LemonColumn = '2 / 5'
const ContentColumn = '6 / 12'

const NotFound: React.FC = () => (
  <ThemeProvider theme={whiteTheme}>
    <SeoComponent title='Big Lemon | Page not Found' />
    <SectionComponent>
      <Grid.Row>
        <Grid.Item column={LemonColumn} alignSelfCenter>
          <Lemon />
        </Grid.Item>
        <Grid.Item column={ContentColumn} alignSelfCenter>
          <Title1>This is awkward..</Title1>
          <Paragraph>
            We can’t seem to find the page you’re looking for, could you try
            going back to the previous page?
          </Paragraph>
          <SpacerComponent bottom='sm' />
          <SquaredLinkComponent to='/' label='Back to home' />
        </Grid.Item>
      </Grid.Row>
    </SectionComponent>
  </ThemeProvider>
)

export default NotFound
